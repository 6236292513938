import React from 'react';
import styled from 'styled-components';
import ReactPlayer from 'react-player'
import { Modal } from '@material-ui/core'
import { colors } from '../styles/variables'


const Container = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border: none;
  background: rgba(0, 0, 0, 0.25);
`

const CloseButton = styled.button`
  position: absolute;
  top: 15px;
  right: 15px;
  color: white;
  background: none;
  border: none;
  cursor: pointer;
  background: ${colors.deiRed};
  padding: 10px 20px 10px 20px;
`

const CloseButtonText = styled.text`
  color: ${colors.white};
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 19px;
  line-height: 24px;
  display: flex;
  align-items: center;
`

interface Props {
  url: string;
  videoOpen: boolean;
  setVideoOpen: (value: boolean) => void;
  videoType?: string
}

const VideoPlayer: React.FC<Props> = ({ url, videoOpen, setVideoOpen, videoType }) => {
  const videoUrl = videoType === 'iframe' ? url : url.concat('?rel=0')
  const close = () => {
    setVideoOpen(!videoOpen)
  }
  return (
    <Modal open={videoOpen} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
      <Container>
        <CloseButton onClick={close}>
          <CloseButtonText>Sulje</CloseButtonText>
        </CloseButton>
        {videoType !== "iframe" ?
          <ReactPlayer url={videoUrl} controls={true} playing={videoOpen} onEnded={() => setVideoOpen(!videoOpen)} /> :
          <div dangerouslySetInnerHTML={{ __html: videoUrl }} />
        }
      </Container>
    </Modal>
  )
}

export default VideoPlayer;
